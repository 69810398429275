<template>
  <div class="content-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>
    <div v-if="referenceNo == '' || referenceNo == null">
      <div v-if="questions.length > 0">      
        <SurveySummary :assessmentDate="assessmentDate" :question-list="questions" v-on:update:score="score = $event"/>
      </div>

      <div class="action-buttons">
        <button class="btn" v-on:click="complete()">Save Assessment</button>

        <!-- <router-link class="btn-accent" to="/survey">Back</router-link> -->
        <button class="btn-accent btn-back" v-on:click="back()">Back</button>
      </div>
    </div>

    <div v-if="referenceNo != ''">
      <p style="font-weight: bold;">Reference ID: <span style="text-decoration: underline">{{ referenceNo }}</span></p>
      <p>You may wish to note the above reference ID for your records.</p>
      <p>Registered users may also review previous E-CAT assessments using the <router-link to="/admin/user-report">Reports page</router-link>.</p>
      <p>Thank you for using E-CAT.</p>
      <!-- <router-link class="btn-accent" to="/" style="margin-top: 20px;">Start Again</router-link> -->
      <button class="btn-accent btn-back" v-on:click="startAgain()">Start Again</button>
    </div>
    
  </div>
</template>

<script>
import SurveySummary from '../../components/SurveySummary.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import authStore from "../../store/auth-store";
import { useToast } from "vue-toastification";
import moment from 'moment'

export default {

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      assessmentDate: moment().format('DD MMM yyyy'),
      isLoading: false,
      questions: [],
      score: 0,
      reportUrl: window.location.origin + "/admin/user-report",

      referenceNo: "",
    }
  },

  mounted() {
    this.questions = this.getQuestions();
  },

  methods: {
    back() {
      if (confirm("Are you sure you want to go back? This will allow you to edit the current assessment.\n\nIf you would like to start a new case assessment, please select ‘Save Assessment’.")) {
        this.$router.push("/survey");
      }
    },
    startAgain() {
      if (confirm("Are you sure you want to start again?")) {
        this.$router.push("/");
      }
    },
    getQuestions() {
      var storedQuestionsJson = localStorage.getItem("@survey-questions");
      if (storedQuestionsJson == null) {
        this.$router.push("/");  
        return;
      }

      return JSON.parse(storedQuestionsJson);
    },

    async complete() {
      // Save our survey
      var questions = this.questions.sort((a, b) => a.position - b.position).map(q => ({
        question: q.title,
        responses: q.responses.filter(r => r.isSelected == true).map(r => ({
          response: r.response,
          weight: r.weight
        }))
      }));
      
      var dto = {
        applicationUserId: this.user?.id,
        score: this.score,
        questions: questions,
        isSimulated: localStorage.getItem("@is-simulated") == "true"
      }

      this.isLoading = true;
      var response = await this.axios.post("/api/survey/post", dto);
      this.isLoading = false;

      if (response.status != 200) {
        this.toast.error("Error saving survey. Please try again");
        return;
      }

      this.toast.success("Successfully saved survey");
      this.referenceNo = response.data;
      localStorage.setItem("@survey-questions", "[]");
      localStorage.setItem("@screening-questions", "[]");
      localStorage.removeItem("@is-simulated");
      // this.$router.push("/");  
    }
  },

  computed: {
    user() {
      return authStore.state.user;
    }
  },

  components: {
    Loading,
    SurveySummary
  },
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    
  }

  .action-buttons {
    a, button {
      margin-bottom: 10px;
    }
  }

  p {
    line-height: 25px;
  }

  .btn-back {
    margin-top: 10px;
    cursor: pointer;
  }

  .refer-section {
    margin-bottom: 20px;
    label {
      display: block;
      margin-bottom: 10px;
    }

    .btn {
      margin-bottom: 10px;
    }
  }
</style>

import Vuex from 'vuex';
import axios from 'axios';

const store = new Vuex.Store({
  state: {
    user: null
  },

  mutations: {
    initialiseStore(state) {
      //TODO: Fire this event before vue startup
      if (localStorage.getItem('auth')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('auth')))
        );
      }
    },
    setUser(state, payload) {
      state.user = payload;
    },    
  },
});

store.subscribe((mutation, state) => {
  localStorage.setItem("auth", JSON.stringify(state));
});

export default store;

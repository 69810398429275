class PostcodeService {
  API_BASE_URL = "";

  constructor() {
    this.API_BASE_URL = "https://api.postcodes.io";
  }

  cleanPostcode(postcode: string) {
    return postcode.replace(/\s+/g, '').toUpperCase();
  }

  public async validatePostcode(postcode: string) {
    try {
      const cleanedPostcode = this.cleanPostcode(postcode);
      const response = await fetch(
        `${this.API_BASE_URL}/postcodes/${encodeURIComponent(cleanedPostcode)}/validate`
      );

      if (!response.ok) {
        throw new Error(`API request failed for postcode: ${cleanedPostcode}`);
      }

      const data = await response.json();

      return {
        postcode: cleanedPostcode,
        isValid: data.result,
        originalInput: postcode
      };
    } catch (error) {
      throw new Error(`Validation failed for ${postcode}: ${error.message}`);
    }
  }

  async getPostcodeCoordinates(postcode: string) {
    try {
      const cleanedPostcode = this.cleanPostcode(postcode);
      const response = await fetch(
        `${this.API_BASE_URL}/postcodes/${encodeURIComponent(cleanedPostcode)}`
      );

      if (!response.ok) {
        throw new Error(`Invalid postcode: ${cleanedPostcode}`);
      }

      const data = await response.json();

      return {
        latitude: data.result.latitude,
        longitude: data.result.longitude
      };
    } catch (error) {
      throw new Error(`Error fetching coordinates for ${postcode}: ${error.message}`);
    }
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Earth's radius in kilometers
    const dLat = this.toRadians(lat2 - lat1);
    const dLon = this.toRadians(lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  }

  toRadians(degrees: number) {
    return degrees * (Math.PI / 180);
  }

  public async getDistanceBetweenPostcodes(postcode1: string, postcode2: string) {
    try {
      // Validate both postcodes first
      const [validation1, validation2] = await Promise.all([
        this.validatePostcode(postcode1),
        this.validatePostcode(postcode2)
      ]);

      if (!validation1.isValid || !validation2.isValid) {
        throw new Error('One or both postcodes are invalid');
      }

      // Get coordinates for both postcodes
      const [coords1, coords2] = await Promise.all([
        this.getPostcodeCoordinates(validation1.postcode),
        this.getPostcodeCoordinates(validation2.postcode)
      ]);

      // Calculate distance
      const distance = this.calculateDistance(
        coords1.latitude, coords1.longitude,
        coords2.latitude, coords2.longitude
      );

      return {
        distance: distance * 0.621371,
        units: 'miles',
        postcodes: {
          from: validation1.postcode,
          to: validation2.postcode
        },
        coordinates: {
          from: coords1,
          to: coords2
        }
      };
    } catch (error) {
      throw new Error(`Failed to calculate distance: ${error.message}`);
    }
  }
}

const postcodeService = new PostcodeService();
export default postcodeService;
